<template>
  <!-- 个人中心首页 -->
  <div>
    <div class="center">
      <!-- 左边列表 -->
      <!-- 面包屑 -->

      <!-- 右边详情 -->
      <div class="right">
        <div class="header">
          <div class="infos">
            <div class="img">
              <img src="../../assets/zxx_img/userlogo.png" alt="" />
            </div>

            <div class="info">
              <div>
                <span class="names" v-if="status == '已通过'">{{
                  record.companyName
                }}</span>
              </div>
              <div class="concat">
                联系方式：<span v-if="status == '已通过'">{{ phone }}</span>
              </div>
              <div style="display:flex;margin-top:10px">
                <div class="concat">
                  交易会员认证状态：
                  <el-link :underline="false" @click="torenzheng" class="state">
                    {{ status }}</el-link
                  >
                </div>

                <div class="concat" style="margin-left:10px">
                  中长期会员认证状态：
                  <el-link
                    :underline="false"
                    @click="torenzhengs"
                    class="state"
                  >
                    {{ mineAuditStatus }}</el-link
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="xiangqing">订单详情</div> -->
        <div class="fenqu">
          <div
            @click="activeBlock(1)"
            :class="{ 'block-active': isActive == 1 }"
            class="block"
          >
            <!-- <div>{{ guapaicaigouCount }}</div> -->
            <div class="block-name">挂牌采购</div>
            <div class="block-main">
              <div class="block-color">
                <i class="hy-icon-dingdan"></i>
              </div>
              <div class="block-nums">
                <p>订单数量</p>
                <div>{{ nums.listedProcurementNum }}</div>
              </div>
            </div>
          </div>
          <div
            @click="activeBlock(2)"
            :class="{ 'block-active': isActive == 2 }"
            class="block"
          >
            <!-- <div>{{ guapaixiaoshouCount }}</div> -->
            <div class="block-name">挂牌销售</div>
            <div class="block-main">
              <div class="block-color">
                <i class="hy-icon-dingdan"></i>
              </div>
              <div class="block-nums">
                <p>订单数量</p>
                <div>{{ nums.listedForSaleNum }}</div>
              </div>
            </div>
          </div>

          <div
            @click="activeBlock(3)"
            :class="{ 'block-active': isActive == 3 }"
            class="block"
          >
            <!-- <div>{{ jingjiacaigou }}</div> -->
            <div class="block-name">竞价采购</div>
            <div class="block-main">
              <div class="block-color">
                <i class="hy-icon-dingdan"></i>
              </div>
              <div class="block-nums">
                <p>订单数量</p>
                <div>{{ nums.biddingNum }}</div>
              </div>
            </div>
          </div>
          <div
            @click="activeBlock(4)"
            :class="{ 'block-active': isActive == 4 }"
            class="block"
          >
            <!-- <div>{{ jingjiaxiaoshou }}</div> -->
            <div class="block-name">竞价销售</div>
            <div class="block-main">
              <div class="block-color">
                <i class="hy-icon-dingdan"></i>
              </div>
              <div class="block-nums">
                <p>订单数量</p>
                <div>{{ nums.auctionSaleNum }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getPage, getCompanyAuthDetail, orderNum } from "@/api/userCenters.js";
import Vue from "vue";
import * as types from "@/store/action-types";
export default {
  components: {
    readcrumb: () => import("../../components/breadcrumb"),
  },
  data() {
    return {
      guapaicaigouCount: 0,
      guapaixiaoshouCount: 0,
      jingjiacaigou: 0,
      jingjiaxiaoshou: 0,
      record: {},
      isActive: 1,
      status: "未认证",
      mineAuditStatus: "未认证",
      breadcrumbDto: [
        { path: "/index", title: "首页" },
        { path: "/userIndex", title: "个人中心" },
      ],
      nums: {},
      phone: "",
    };
  },
  created() {
    // 获取数据
    this.getPages();
    // 获取企业详情
    this.getCompanyAuthDetail();
    this.phone = window.localStorage.getItem("phone");
  },
  methods: {
    torenzheng() {
      if (!this.beforhandle("personalCenter:enterpriseCert")) return;
      this.$router.push("/authentication");
    },
    torenzhengs() {
      this.$router.push({
        path:"/MediumTerm",
        query:{
          type:this.mineAuditStatus
        }
      });
    },
    activeBlock(id) {
      this.isActive = id;
    },
    // 获取分类数据

    getPages() {
      orderNum().then((res) => {
        console.log(res);
        this.nums = res.data;
      });
    },

    // async getPages() {
    //   // 挂牌采购
    //   const config = {
    //     orderType: 2,
    //     page: 1,
    //     size: 10,
    //     tradeType: 1,
    //   };
    //   // 挂牌销售
    //   const config1 = {
    //     orderType: 1,
    //     page: 1,
    //     size: 10,
    //     tradeType: 1,
    //   };
    //   // 挂牌采购
    //   const config2 = {
    //     orderType: 2,
    //     page: 1,
    //     size: 10,
    //     tradeType: 2,
    //   };
    //   // 挂牌销售
    //   const config3 = {
    //     orderType: 1,
    //     page: 1,
    //     size: 10,
    //     tradeType: 2,
    //   };
    //   let res = await getPage(config);
    //   let res1 = await getPage(config1);
    //   let res2 = await getPage(config2);
    //   let res3 = await getPage(config3);

    //   this.guapaicaigouCount = res.data.total;
    //   this.guapaixiaoshouCount = res1.data.total;
    //   this.jingjiacaigou = res2.data.total;
    //   this.jingjiaxiaoshou = res3.data.total;
    // },
    // 获取企业详情
    getCompanyAuthDetail() {
      getCompanyAuthDetail().then((res) => {
        this.record = res.data;
        console.log(res.data.auditStatus);
        if (res.data.auditStatus == 0) {
          this.status = "审核中";
        } else if (res.data.auditStatus == 1) {
          this.status = "已通过";
        } else {
          this.status = "审核拒绝";
        }
        if (res.data.mineAuditStatus == -1) {
          this.mineAuditStatus = "未认证";
        } else if (res.data.mineAuditStatus == 0) {
          this.mineAuditStatus = "待审核";
        } else if (res.data.mineAuditStatus == 1) {
          this.mineAuditStatus = "审核通过";
        } else if (res.data.mineAuditStatus == 2) {
          this.mineAuditStatus = "审核拒绝";
        }
        console.log(this.record);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.center {
  width: 1195px;
  display: flex;
  justify-content: flex-start;
  background: #f7f7f7;
  position: relative;
  .nav-title {
    position: absolute;
    top: 3px;
    left: 20px;
    font-size: 14px;
    color: #333333;
    span {
      margin: 0 10px;
    }
  }
  .left {
    width: 186px;
    margin: 20px;
    border: 1px solid #e5e5e5;
    border-top: 0;
    text-align: center;
    color: #666666;
    height: 663px;
    overflow: hidden;
    overflow-y: auto;
    .outer-box {
      background-color: #ffffff;
      padding-top: 12px;
    }

    .one {
      font-size: 18px;
      height: 47px;
      font-weight: 550;
      line-height: 47px;
      background: #f3f3f3;
      border-bottom: 1px solid #e5e5e5;
      border-top: 1px solid #e5e5e5;
    }

    .two {
      font-size: 16px;
      display: block;
      height: 40px;
      line-height: 40px;
      margin: 0 auto;
      width: 136px;
    }
    .outer-box1 {
      padding-top: 0;
      .two {
        font-size: 16px;
        font-weight: 550px;
        font-weight: 550;
      }
    }
    .two-active {
      height: 40px;
      background: linear-gradient(-45deg, #00a0ff, #0a65ff);
      box-shadow: 0px 5px 20px 0px rgba(143, 170, 249, 0.47);
      border-radius: 20px;
      line-height: 40px;
      color: #ffffff;
    }
    .three {
      font-size: 14px;
      display: block;
      height: 30px;
      line-height: 30px;
      margin: 0 auto;
      width: 136px;
      color: #666666;
    }
  }
  .right {
    margin: 20px;
    width: calc(100% - 300px);
    border: 1px solid #e5e5e5;
    background-color: #ffffff;
    .header {
      display: flex;
      border-bottom: 1px #c9c9c9 dashed;
      height: 218px;
      align-items: center;
      .img {
        width: 84px;
        height: 84px;
        margin-left: 50px;
        border: 1px solid #e5e5e5;
        border-radius: 50%;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .infos {
        display: flex;
      }
      .info {
        font-size: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        margin-left: 30px;
        color: #333333;
        height: 84px;
        .names {
          font-size: 18px;
        }
        .concat {
          font-size: 14px;
          .state {
            height: 29px;
            line-height: 29px;
            width: 78px;
            text-align: center;
            background: #f0f0f0;
            border-radius: 15px;
            font-size: 14px;
            color: #fb0606;
          }
        }
      }
    }
    .xiangqing {
      margin: 20px 0;
      height: 50px;
      line-height: 50px;
      font-size: 30px;
      text-align: center;
    }
    .fenqu {
      display: flex;
      justify-content: space-between;
      font-size: 30px;
      padding: 15px 26px;
      flex-wrap: wrap;
      .block {
        height: 174px;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        margin-top: 27px;
        .block-main {
          display: flex;
          padding-top: 24px;
          padding-left: 32px;
          .block-color {
            width: 77.3px;
            height: 77.3px;
            border-radius: 50%;
            border: 1px solid #dddddd;
            display: flex;
            align-items: center;
            justify-content: center;
            i {
              font-size: 60px;
              color: #096cff;
            }
          }
          .block-nums {
            margin-left: 27px;
            p {
              font-size: 20px;
            }
            div {
              font-size: 30px;
              color: #ff0000;
              margin-top: 18px;
              span {
                font-size: 20px;
              }
            }
          }
        }

        .block-name {
          color: #333333;
          font-size: 18px;
          height: 46px;
          line-height: 46px;
          border-bottom: 1px solid #dddddd;
          padding-left: 18px;
          color: #333333;
          font-weight: bold;
        }
      }
      .block:hover {
        background: linear-gradient(-45deg, #00a0ff, #0a65ff);
        .block-name {
          color: #ffffff;
        }
        .block-color {
          border: 1px solid #ffffff !important;
          i {
            color: #ffffff !important;
          }
        }
        .block-over {
          background: linear-gradient(-45deg, #00a0ff, #0a65ff);
          color: #ffffff;
        }
        .block-nums {
          color: #ffffff !important;
        }
        .block-nums div {
          color: #ffffff !important;
        }
      }
    }
    .fenqu > div {
      width: 49.5%;
    }
  }
}
</style>
